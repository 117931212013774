import { AuthProvider } from "../providers/auth";
import { DataProvider } from "../providers/data";

export type Dashboard = { path: string; initPath: string };
export type Login = { path: string; usernameField?: string; passwordField?: string };
export type Register = { require: true; path: string } | { require: false };
export type ResetPassword = { require: true; path: string } | { require: false };

export interface InitialState {
	authProvider: any;
	dataProvider: any;
	isAuthentication: boolean;
	layout: {
		dashboard: Dashboard;
		login: Login;
		register: Register;
		resetPassword: ResetPassword;
	};
	rememberMe: boolean;
	urlApi: string;
	user: { [k: string]: any };
}

export const initialState: InitialState = {
	authProvider: AuthProvider,
	dataProvider: DataProvider,
	isAuthentication: false,
	layout: {
		dashboard: { path: "dashboard", initPath: "" },
		login: { path: "sign-in", usernameField: "email", passwordField: "password" },
		register: { require: false },
		resetPassword: { require: false },
	},
	rememberMe: true,
	urlApi: null,
	user: null,
};
