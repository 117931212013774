import { ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { Blueprint } from "blueprint/blueprint";
import { register as registerComponent } from "blueprint/resources/components";
import { BlueprintProps } from "blueprint/types/blueprint";

import theme from "../assets/theme";
import themeDark from "../assets/theme-dark";
import brandWhite from "../assets/images/logo-ct.png";
import brandDark from "../assets/images/logo-ct-dark.png";
import { Context, context } from "./context";
import { EAction } from "./context/action";
import { Dashboard, initialState, Login, Register, ResetPassword } from "./context/initial-state";
import { reducer } from "./context/reducer";
import { MaterialUIControllerProvider, setMiniSidenav, useMaterialUIController } from "./context-theme";
import { AuthProvider } from "./providers/auth";
import { DataProvider } from "./providers/data";
import { SidebarItem } from "./types/sidebarItem";
import Sidenav from "./examples/Sidenav";
import { useCheckLogin } from "./hooks/useLogin";

interface ThemeProviderProps {
	menu: SidebarItem[];
	children: ReactNode;
}

function ThemeProvider(props: ThemeProviderProps) {
	const ctx = useContext<Context>(context);
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [{ miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode }, dispatch] =
		useMaterialUIController();
	const { isAuthentication, loading } = useCheckLogin();
	const nav = useNavigate();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	useEffect(() => {
		if (!loading && !isAuthentication) {
			nav(`/${ctx.layout.login.path || "sign-in"}`, { replace: true });
		}
	}, [isAuthentication, loading]);

	return (
		<MuiThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && (
				<Sidenav
					color={sidenavColor}
					brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
					brandName="Admin"
					routes={props.menu}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				/>
			)}
			{props.children}
		</MuiThemeProvider>
	);
}

interface ContextProviderProps {
	authProvider?: any;
	children: ReactNode;
	dataProvider?: any;
	layout?: {
		dashboard?: Dashboard;
		login?: Login;
		register?: Register;
		resetPassword?: ResetPassword;
	};
	urlApi?: string;
}

function ContextProvider(props: ContextProviderProps) {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		...state,
		setAuthProvider: (authProvider: any) => {
			dispatch({ type: EAction.SET_AUTH_PROVIDER, payload: authProvider });
		},
		setDataProvider: (dataProvider: any) => {
			dispatch({ type: EAction.SET_DATA_PROVIDER, payload: dataProvider });
		},
		setLayoutDashboard: (payload: Dashboard) => {
			dispatch({ type: EAction.SET_LAYOUT_DASHBOARD, payload: payload });
		},
		setLayoutLogin: (payload: Login) => {
			dispatch({ type: EAction.SET_LAYOUT_LOGIN, payload: payload });
		},
		setLayoutRegister: (payload: Register) => {
			dispatch({ type: EAction.SET_LAYOUT_REGISTER, payload: payload });
		},
		setLayoutResetPassword: (payload: ResetPassword) => {
			dispatch({ type: EAction.SET_LAYOUT_RESET_PASSWORD, payload: payload });
		},
		setRememberMe: (rememberMe: boolean) => {
			dispatch({ type: EAction.SET_REMEMBER_ME, payload: rememberMe });
		},
		setSession: (user: any) => {
			dispatch({ type: EAction.SET_SESSION, payload: { user } });
		},
		setUrlApi: (url: string) => {
			dispatch({ type: EAction.SET_URL_API, payload: url });
		},
		logout: () => {
			dispatch({ type: EAction.LOGOUT, payload: {} });
		},
	};

	useEffect(() => {
		if (props.authProvider) {
			value.setAuthProvider(props.authProvider);
		} else {
			value.setAuthProvider(AuthProvider);
		}

		if (props.dataProvider) {
			value.setDataProvider(props.dataProvider);
		} else {
			value.setDataProvider(DataProvider);
		}

		if (props.layout) {
			if (props.layout.dashboard) {
				value.setLayoutDashboard(props.layout.dashboard);
			}
			if (props.layout.login) {
				value.setLayoutLogin(props.layout.login);
			}
			if (props.layout.register) {
				value.setLayoutRegister(props.layout.register);
			}
			if (props.layout.resetPassword) {
				value.setLayoutResetPassword(props.layout.resetPassword);
			}
		}

		if (props.urlApi) {
			value.setUrlApi(props.urlApi);
		}
	}, []);

	return <context.Provider value={value}>{state.urlApi && props.children}</context.Provider>;
}

interface AdminProvidersProps extends BlueprintProps {
	authProvider?: any;
	dataProvider?: any;
	layout?: {
		dashboard?: Dashboard;
		login?: Login;
		register?: Register;
		resetPassword?: ResetPassword;
	};
	menu: SidebarItem[];
	urlApi?: string;
}

registerComponent("admin:mainLayout", function (props: AdminProvidersProps) {
	return (
		<MaterialUIControllerProvider>
			<ContextProvider
				authProvider={props.authProvider}
				dataProvider={props.dataProvider}
				layout={props.layout}
				urlApi={props.urlApi}
			>
				<ThemeProvider menu={props.menu}>
					<Outlet />
				</ThemeProvider>
			</ContextProvider>
		</MaterialUIControllerProvider>
	);
});

export function AdminProvider({ routes, ...props }: AdminProvidersProps) {
	const { dashboard, login, register, resetPassword } = props.layout || {};
	return (
		<Blueprint
			routes={[
				{
					path: `/${login?.path || "sign-in"}`,
					component: { name: "admin:mainLayout", props: props },
					children: [{ path: "", component: { name: "sign-in" } }],
				},
				{
					path: "/logout",
					component: { name: "admin:mainLayout", props: props },
					children: [{ path: "", component: { name: "logout" } }],
				},
				...(resetPassword?.require
					? [
							{
								path: `/${resetPassword?.path || "reset-password"}`,
								component: { name: "admin:mainLayout", props: props },
								children: [{ path: "", component: { name: "reset-password" } }],
							},
					  ]
					: []),
				...(register?.require
					? [
							{
								path: `/${register?.path || "sing-up"}`,
								component: { name: "admin:mainLayout", props: props },
								children: [{ path: "", component: { name: "reset-password" } }],
							},
					  ]
					: []),
				{
					path: `/${dashboard?.path || "dashboard"}`,
					component: { name: "admin:mainLayout", props: props },
					children: routes,
				},
				{ path: "*", component: { name: "Navigate", props: { to: `/${login?.path || "sign-in"}` } } },
			]}
		/>
	);
}
