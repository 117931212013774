import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeInputFile, makeList } from "./blueprints-utilities";

const fields = [
	makeInput("title", "Name", "text"),
	makeInputFile("image", "Image", "categories/upload-image"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "categories",
		children: [
			{
				path: "",
				component: makeList("Categories", "categories", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Name", accessor: "title" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['title'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create Categories", "categories", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Categories", "categories", fields, true),
			},
		],
	},
];
