import { TBlueprintRoute } from "../blueprint/types/blueprint";
import {makeForm, makeInput, makeInputFile, makeInputReference, makeList} from "./blueprints-utilities";

const fields = [
	makeInputReference("benefit_id", "Beneficio", "benefits", "id", "{{id}} - {{commerce.name}} - {{title}}", "title"),
	makeInputFile("path", "Banner", "banners/upload-image"),
	makeInput("order", "Position", "number"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "banners",
		children: [
			{
				path: "",
				component: makeList("Banners", "banners", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Position", accessor: "order" },
					{ Header: "Banner", accessor: "path" },
					{ Header: "Beneficio", accessor: "benefit.title" },
					{ Header: "action", accessor: "_action", width: "10%" },
				]),
			},
			{
				path: "create",
				component: makeForm("Create Banner", "banners", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Banner", "banners", fields, true),
			},
		],
	},
];
