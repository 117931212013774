import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { register } from "blueprint/resources/components";

// MUI Components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// MD Components
import MDAlert from "admin/components/MDAlert";
import MDAvatar from "admin/components/MDAvatar";
import MDBadge from "admin/components/MDBadge";
import MDBadgeDot from "admin/components/MDBadgeDot";
import MDBox from "admin/components/MDBox";
import MDButton from "admin/components/MDButton";
import MDDatePicker from "admin/components/MDDatePicker";
import MDDropzone from "admin/components/MDDropzone";
import MDEditor from "admin/components/MDEditor";
import MDInput from "admin/components/MDInput";
import MDPagination from "admin/components/MDPagination";
import MDProgress from "admin/components/MDProgress";
import MDSnackbar from "admin/components/MDSnackbar";
import MDSocialButton from "admin/components/MDSocialButton";
import MDTypography from "admin/components/MDTypography";

// Custom Components
import DataTable from "admin/examples/Tables/DataTable";
import { Input } from "admin/examples/Input";
import { Checkbox } from "admin/examples/InputCheckbox";
import { InputFile } from "admin/examples/InputFile";
import { InputReference } from "admin/examples/InputReference";
import { InputSelect } from "./examples/InputSelect";
import { SingleSelect } from "./examples/SingleSelect";

// Layouts
import Illustration from "admin/layouts/authentication/sign-in/illustration";
import ResetPassword from "admin/layouts/authentication/reset-password/illustration";
import List from "admin/layouts/dashboards/list";
import Form from "admin/layouts/dashboards/form";
import Logout from "admin/layouts/dashboards/logout";
import { Loading } from "./examples/Loading";

// MUI register
register("Card", Card);
register("Icon", Icon);
register("IconButton", IconButton);

// MD register
register("MDAlert", MDAlert);
register("MDAvatar", MDAvatar);
register("MDBadge", MDBadge);
register("MDBadgeDot", MDBadgeDot);
register("MDBox", MDBox);
register("MDButton", MDButton);
register("MDDatePicker", MDDatePicker);
register("MDDropzone", MDDropzone);
register("MDEditor", MDEditor);
register("MDInput", MDInput);
register("MDPagination", MDPagination);
register("MDProgress", MDProgress);
register("MDSnackbar", MDSnackbar);
register("MDSocialButton", MDSocialButton);
register("MDTypography", MDTypography);

// Custom register
register("DataTable", DataTable);
register("Input", Input);
register("InputCheckbox", Checkbox);
register("InputReference", InputReference);
register("InputFile", InputFile);
register("InputImage", InputFile);
register("InputSelect", InputSelect);
register("SingleSelect", SingleSelect);

// utilities
register("Navigate", Navigate);
register("Fragment", Fragment);

// Layouts
register("sign-in", <Illustration />);
register("logout", <Logout />);
register("reset-password", <ResetPassword />);
register("loading", Loading);
register("list", List);
register("form", Form);
