import { FC, ForwardRefExoticComponent, isValidElement, ReactNode, RefAttributes } from "react";
import { Resources } from "./resources";

function isElement(obj: any) {
	return isValidElement(obj) || ["string", "number", "boolean"].includes(typeof obj);
}

export function register(
	key: string,
	component:
		| ReactNode
		| FC<any>
		| ForwardRefExoticComponent<Pick<any, string | number | symbol> & RefAttributes<unknown>>,
	replace: boolean = false
) {
	const isFC = !isElement(component);
	Resources.setResource(
		{ key: `component:${key}`, type: isFC ? "Component" : "Element", resource: component },
		replace
	);
}

export function getComponent(key: string) {
	return Resources.getResource(`component:${key}`);
}
