type Item = { key: string; type: ResourceType; resource: any };

type ResourceType = "Element" | "Component" | "image" | "url" | string;

export class Resources {
	private static resources: { [k: string]: Item } = {};

	static setResource(item: Item, replace: boolean = false) {
		if (!replace && Resources.resources[item.key]) {
			throw `resource "${item.key}" already exists`;
		}

		Resources.resources[item.key] = item;
	}

	static getResource(key: string): Item {
		if (!Resources.resources[key]) {
			throw `component "${key}" does not exist`;
		}

		return Resources.resources[key];
	}
}
