import React from "react";
import { BrowserRouter } from "react-router-dom";

import { BlueprintProps } from "./types/blueprint";
import { Routes } from "./routes";

export function Blueprint(props: BlueprintProps) {
	return (
		<BrowserRouter>
			<Routes routes={props.routes} />
		</BrowserRouter>
	);
}
