import { useCallback, useContext } from "react";
import axios from "axios";
import { FileWithPath } from "react-dropzone";
import { context, Context } from "../../context";

export function useS3InputFile(resource: string) {
	const ctx = useContext<Context>(context);

	const uploadFile = useCallback(
		function (file: FileWithPath) {
			const token = localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
			return axios
				.post(
					`${ctx.urlApi}/${resource}`,
					{ name: file.name, type: file.type, size: file.size },
					{ headers: { authorization: `Bearer ${token}` } }
				)
				.then(async ({ data }) => {
					await axios.put(data.url, file);
					return data;
				});
		},
		[resource]
	);

	return { uploadFile };
}
