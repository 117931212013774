import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { context, Context } from "admin/context";
import PageLayout from "admin/examples/LayoutContainers/PageLayout";
import { AuthProvider } from "admin/providers/auth";

function Logout() {
	const ctx = useContext<Context>(context);
	const nav = useNavigate();

	const authProvider = useMemo<AuthProvider<any>>(() => new ctx.authProvider(ctx.urlApi), []);

	useEffect(() => {
		authProvider.logout().then(() => {
			ctx.logout();
			nav(`/${ctx.layout.login.path || "sign-in"}`);
		});
	}, []);

	return (
		<PageLayout background="white">
			<div />
		</PageLayout>
	);
}

export default Logout;
