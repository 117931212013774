import React, { forwardRef } from "react";
import MDBox from "admin/components/MDBox";
import MDInput, { MDInputProps } from "admin/components/MDInput";
import { Label } from "./label";

export const Input = forwardRef((props: MDInputProps, ref) => {
	const { label, value, ...propsInput } = props;
	return (
		<MDBox mb={2} position={"relative"}>
			{label && <Label label={label} />}
			<MDInput ref={ref} value={value || ""} {...propsInput} />
		</MDBox>
	);
});
