import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";
import {SxProps} from "@mui/material";

type Props = {
	label: string;
	name: string;
	onChange: (value: any) => void;
	value: boolean;
	sk?: SxProps
};

export function Checkbox(props: Props) {
	return (
		<FormGroup sx={props.sk}>
			<FormControlLabel
				control={
					<MuiCheckbox
						checked={props.value || false}
						onChange={(e) => props.onChange({ target: { name: props.name, value: e.target.checked } })}
					/>
				}
				label={props.label}
			/>
		</FormGroup>
	);
}
