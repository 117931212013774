import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeCheckbox, makeForm, makeInput, makeInputFile, makeInputReference, makeList } from "./blueprints-utilities";

const fields = [
	makeInput("name", "Nombre", "text"),
	makeInput("description", "Descripcion", "textarea"),
	makeCheckbox("allow_minors", "Se Permiten Menores"),
	makeCheckbox("allow_consume_in_site", "Se Permite Consumo en Sitio"),
	makeCheckbox("allow_pets", "Se Permiten Mascotas"),
	makeCheckbox("has_delivery", "Tiene Delivery", { sk: { mb: 3 } }),
	makeInputFile("banner_image", "Banner", "commerces/upload-image"),
	makeInputFile("image", "Logo", "commerces/upload-image"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "commerces",
		children: [
			{
				path: "",
				component: makeList("Commerces", "commerces", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Nombre", accessor: "name" },
					{ Header: "Descripcion", accessor: "description" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['name', 'description'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create Commerce", "commerces", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Commerce", "commerces", fields, true),
			},
		],
	},
];
