import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeList } from "./blueprints-utilities";

const fields = [makeInput("name", "Nombre", "text")];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "applications",
		children: [
			{
				path: "",
				component: makeList("Applications", "applications", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Nombre", accessor: "name" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['name'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create Application", "applications", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Application", "applications", fields, true),
			},
		],
	},
];
