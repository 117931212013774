import React, { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "admin/components/MDBox";
import MDInput, { MDInputProps } from "admin/components/MDInput";
import { Label } from "../Input/label";
import { context, Context } from "../../context";
import { IDataProvider } from "../../types/data-provider";
import mustache from "mustache";
import _ from "lodash";

interface InputReferenceProps extends MDInputProps {
	resource: string;
	resourceLabel: string;
	resourceSearch: string;
	resourceValue: string;
}

export const InputReference = forwardRef((props: InputReferenceProps, ref: ForwardedRef<any>) => {
	const { label, ...propsInput } = props;
	const ctx = useContext<Context>(context);
	const [firstLoad, setFirstLoad] = useState<boolean>(true);
	const [value, setValue] = useState("");
	const [options, setOptions] = useState([{ label: "", value: "" }]);

	const dataProvider = useMemo<IDataProvider<any, any>>(() => {
		const provider = new ctx.dataProvider(ctx.urlApi);
		provider.resource = props.resource;
		return provider;
	}, [props.resource]);

	const inputValue = useMemo(() => {
		const option = options.find((it) => it.value === propsInput.value);
		return option ? option.label : value;
	}, [options, propsInput.value]);

	useEffect(() => {
		const where = firstLoad ? {} : { [props.resourceSearch]: { $iLike: `%${value}%` } };
		dataProvider.find({ where, pagination: { page: 1, perPage: 10 } }).then(async ({ data }) => {
			const firstData = (
				await dataProvider.find({
					where: { [props.resourceValue]: propsInput.value },
					pagination: { page: 1, perPage: 10 },
				})
			).data.map((it) => ({
				value: it[props.resourceValue],
				label: mustache.render(props.resourceLabel, it),
			}));

			const totalData = data.map((it) => ({
				value: it[props.resourceValue],
				label: mustache.render(props.resourceLabel, it),
			}));

			const finalData = _.uniqBy([...firstData, ...totalData], "value");
			setOptions([{ value: "", label: "Disable" }, ...finalData]);
			setFirstLoad(false);
		});
	}, [firstLoad, value]);

	return (
		<MDBox mb={2} position={"relative"}>
			{label && <Label label={label} />}
			<Autocomplete
				ref={ref}
				fullWidth
				disableClearable
				value={inputValue}
				options={options.map((it) => it.label)}
				onChange={(event: any, newValue: any) => {
					propsInput.onChange({
						...event,
						target: {
							...event.target,
							name: propsInput.name,
							value: options.find((it) => it.label === newValue).value,
						},
					});
				}}
				size="medium"
				renderInput={(params) => (
					<MDInput
						{...params}
						onChange={(e: any) => {
							setValue(e.target.value);
						}}
					/>
				)}
			/>
		</MDBox>
	);
});
