/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

import { AuthProvider } from "admin/providers/auth";

// Material Dashboard 2 PRO React TS components
import MDBox from "admin/components/MDBox";
import MDTypography from "admin/components/MDTypography";
import MDInput from "admin/components/MDInput";
import MDButton from "admin/components/MDButton";

// Authentication layout components
import IllustrationLayout from "admin/layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { Context, context } from "admin/context";
import { useLogin } from "admin/hooks/useLogin";

function Illustration(): JSX.Element {
	const ctx = useContext<Context>(context);
	const { fields, setFields, isAuthentication, handleSubmit, handleSetRememberMe } = useLogin();
	const nav = useNavigate();

	useEffect(() => {
		if (isAuthentication) {
			nav(`/dashboard/${ctx.layout.dashboard.initPath}`, { replace: true });
		}
	}, [isAuthentication]);

	return (
		<IllustrationLayout title="Sign In" description="Enter your email and password to sign in" illustration={bgImage}>
			<MDBox
				component="form"
				role="form"
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit(fields);
				}}
			>
				<MDBox mb={2}>
					<MDInput
						type="email"
						label="Email"
						fullWidth
						value={fields.email}
						onChange={(e: any) => {
							setFields((prev) => ({ ...prev, email: e.target.value }));
						}}
					/>
				</MDBox>
				<MDBox mb={2}>
					<MDInput
						type="password"
						label="Password"
						fullWidth
						value={fields.password}
						onChange={(e: any) => {
							setFields((prev) => ({ ...prev, password: e.target.value }));
						}}
					/>
				</MDBox>
				<MDBox display="flex" alignItems="center" ml={-1}>
					<Switch checked={ctx.rememberMe} onChange={handleSetRememberMe} />
					<MDTypography
						variant="button"
						fontWeight="regular"
						color="text"
						onClick={handleSetRememberMe}
						sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
					>
						&nbsp;&nbsp;Remember me
					</MDTypography>
				</MDBox>
				<MDBox mt={4} mb={1}>
					<MDButton variant="gradient" color="info" size="large" type={"submit"} fullWidth>
						sign in
					</MDButton>
				</MDBox>
			</MDBox>
		</IllustrationLayout>
	);
}

export default Illustration;
