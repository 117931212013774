import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { context, Context } from "admin/context";
import { IDataProvider } from "admin/types/data-provider";

const SwalWithReact = withReactContent(Swal);

export type Row = { id: number } & { [key: string]: any };

export type Options = {
	resource: string;
	showDeleteConfirmation?: boolean;
	deleteConfirmation?: {
		text?: string;
		confirmButtonText?: string;
		cancelButtonText?: string;
	};
	searchFields?: string[];
	transforms?: { [p: string]: (v: any) => any };
};

export function makeRemoveAlert(options: Options["deleteConfirmation"], onConfirm: () => void) {
	SwalWithReact.fire({
		text: options.text || "This action is not reversible, are you sure you want to delete this record?",
		showCancelButton: true,
		showConfirmButton: true,
		confirmButtonColor: "#ff0000",
		confirmButtonText: options.confirmButtonText || "Delete",
		cancelButtonText: options.cancelButtonText || "Don't delete",
	}).then((result) => result.isConfirmed && onConfirm());
}

export function useProvider(resource: string) {
	const ctx = useContext<Context>(context);

	return useMemo<IDataProvider<any, any>>(() => {
		const provider = new ctx.dataProvider(ctx.urlApi);
		provider.resource = resource;
		return provider;
	}, [resource, ctx.urlApi]);
}

export function useBulkCreate(resource: string) {
	const [bulkProcess, setBulkProcess] = useState(0);
	const dataProvider = useProvider(resource);

	const bulkCreate = useCallback(async (data: any[]) => {
		for (let i = data.length - 1; i >= 0; i--) {
			await dataProvider.create(data[i]).catch(() => {
				setBulkProcess(0);
				throw new Error("Error creating code");
			});
			setBulkProcess(100 - (i / data.length) * 100);
		}
		setBulkProcess(0);
	}, [dataProvider]);

	return { bulkCreate, bulkProcess };
}

export function useRemove(resource: string, showConfirmation: boolean, options: Options["deleteConfirmation"]) {
	const dataProvider = useProvider(resource);

	const remove = useCallback((id: number) => {
		return new Promise((resolve, reject) => {
			const removeItem = () => dataProvider.removeOne(id).then((data) => resolve(data)).catch((err) => reject(err));

			if (showConfirmation) {
				makeRemoveAlert(options, () => removeItem().then(() => SwalWithReact.fire("Deleted!", "", "success")));
			}
			else {
				removeItem().then();
			}
		});
	}, [dataProvider]);

	return { remove };
}

export function useList(options: Options) {
	const { deleteConfirmation, resource, showDeleteConfirmation, searchFields, transforms } = options;
	const [rows, setRows] = useState<{ [p1: string]: Row[] }>({});
	const [total, setTotal] = useState<number>(0);
	const [errorSB, setErrorSB] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const openErrorSB = () => setErrorSB(true);
	const closeErrorSB = () => setErrorSB(false);

	const dataProvider = useProvider(resource);

	const setData = useCallback((data: any[]) => {
		setRows({
			[resource]: data.map((row) => {
				for (const k in row) {
					if (transforms && transforms[k]) row[k] = transforms[k](row[k]);
				}
				return row;
			}),
		});
	}, [resource, transforms]);

	const getRawData = useCallback((perPage = 10, page = 1, search?: string) => {
		return dataProvider
			.find({ ...(search && searchFields ? { where: { $or: searchFields.map(f => ({ [f]: { $iLike: `%${search}%` } })) } } : {}), sort: { field: "id", order: "ASC" }, pagination: { page, perPage } })
	}, [dataProvider, setData]);

	const getData = useCallback((perPage = 10, page = 1, search?: string) => {
		return getRawData(perPage, page, search)
			.then(({ data, total }) => {
				setTotal(total);
				setData(data);
			});
	}, [dataProvider, setData]);

	const { bulkCreate, bulkProcess } = useBulkCreate(resource);

	const { remove } = useRemove(resource, showDeleteConfirmation || false, deleteConfirmation || {});

	useEffect(() => {
		getData().catch();
	}, [dataProvider]);

	return {
		bulkCreate,
		bulkProcess,
		getData,
		getRawData,
		remove,
		rows: rows[resource] || [],
		total,
		errorSB,
		errorMessage,
		openErrorSB,
		closeErrorSB,
	};
}
