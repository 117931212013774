/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "admin/components/MDBox";
import MDTypography from "admin/components/MDTypography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Declaring props types for the Breadcrumbs
interface Props {
	icon: ReactNode;
	title: string;
	route: string | string[];
	light?: boolean;
	backOption?: boolean;
	[key: string]: any;
}

function Breadcrumbs({ icon, title, route, light, backOption }: Props): JSX.Element {
	const routes: string[] | any = route.slice(0, -1);

	return (
		<MDBox mr={{ xs: 0, xl: 8 }}>
			<MuiBreadcrumbs
				sx={{
					"& .MuiBreadcrumbs-separator": {
						color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
					},
				}}
			>
				<Link to="/dashboard/benefits">
					<MDTypography
						component="span"
						variant="body2"
						color={light ? "white" : "dark"}
						opacity={light ? 0.8 : 0.5}
						sx={{ lineHeight: 0 }}
					>
						<Icon>{icon}</Icon>
					</MDTypography>
				</Link>
				{routes.map((el: string, i: number) => (
					<Link to={`/${routes.slice(0, i + 1).join("/")}`} key={el}>
						<MDTypography
							component="span"
							variant="button"
							fontWeight="regular"
							textTransform="capitalize"
							color={light ? "white" : "dark"}
							opacity={light ? 0.8 : 0.5}
							sx={{ lineHeight: 0 }}
						>
							{el}
						</MDTypography>
					</Link>
				))}
				<MDTypography
					variant="button"
					fontWeight="regular"
					textTransform="capitalize"
					color={light ? "white" : "dark"}
					sx={{ lineHeight: 0 }}
				>
					{title.replace("-", " ")}
				</MDTypography>
			</MuiBreadcrumbs>
			{backOption ? (
				<Link to={`/${routes.slice(0, 2).join("/")}`}>
					<MDTypography
						fontWeight="bold"
						mt={3}
						textTransform="capitalize"
						variant="h4"
						color={light ? "white" : "dark"}
						noWrap
					>
						<ArrowBackIosIcon />
					</MDTypography>
				</Link>
			) : null}
		</MDBox>
	);
}

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
	light: false,
};

export default Breadcrumbs;
