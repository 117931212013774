import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeInputReference, makeList } from "./blueprints-utilities";

const fields = [
	makeInputReference("user_id", "Users", "users", "id", "{{id}} - {{email}}", "email"),
	makeInputReference("commerce_id", "Commerces", "commerces", "id", "{{id}} - {{name}}", "name"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "commerces-owners",
		children: [
			{
				path: "",
				component: makeList("Owners", "commerces-users", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Commerce", accessor: "commerce.name" },
					{ Header: "User", accessor: "user.name" },
					{ Header: "action", accessor: "_action", width: "10%" },
				]),
			},
			{
				path: "create",
				component: makeForm("Create Owners", "commerces-users", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Owners", "commerces-users", fields, true),
			},
		],
	},
];
