import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { IDataProvider, TEntity, TEntityList, TQuery } from "../types/data-provider";

export class DataProvider<Input, Output extends TEntity> implements IDataProvider<Input, Output> {
	private _resource: string;
	private _host: string;
	private _storage: Storage = localStorage;
	private axios: AxiosInstance;

	public limit = 10;

	constructor(host: string) {
		this.host = host;
		this.makeAxios();
	}

	private makeAxios() {
		this.axios = axios.create({
			baseURL: this.host,
			timeout: 60000,
		});
	}

	private getHeaders(): AxiosRequestHeaders {
		const headers: AxiosRequestHeaders = {};

		const token = this.storage.getItem("jwt");
		if (token) {
			headers["authorization"] = `Bearer ${token}`;
		}

		headers["application_id"] = 1;

		return headers;
	}

	rawPost(url: string, data: any[]): Promise<any> {
		return this.axios
			.post<Output>(`${this.resource}/${url}`, data, {
				headers: this.getHeaders(),
			})
			.then(({ data }) => data);
	}

	create(data: Input): Promise<Output> {
		return this.axios.post<Output>(this.resource, data, { headers: this.getHeaders() }).then(({ data }) => data);
	}

	createBulk(data: Input[]): Promise<Output[]> {
		throw "Not Implemented";
	}

	find(query: TQuery<Input>): Promise<TEntityList<Output>> {
		return this.axios
			.get<TEntityList<Output>>(this.resource, { headers: this.getHeaders(), params: query })
			.then(({ data }) => data);
	}

	get(id: number): Promise<Output> {
		return this.axios.get<Output>(`${this.resource}/${id}`, { headers: this.getHeaders() }).then(({ data }) => data);
	}

	removeMany(query: TQuery<Input>): Promise<Output[]> {
		throw "Not Implemented";
	}

	removeOne(id: number): Promise<Output> {
		return this.axios.delete<Output>(`${this.resource}/${id}`, { headers: this.getHeaders() }).then(({ data }) => data);
	}

	updateMany(query: TQuery<Input>, data: Input): Promise<Output[]> {
		throw "Not Implemented";
	}

	updateOne(id: number, data: Input): Promise<Output> {
		return this.axios
			.patch<Output>(`${this.resource}/${id}`, data, { headers: this.getHeaders() })
			.then(({ data }) => data);
	}

	/*
	 * GETTERS AND SETTERS
	 */

	get host(): string {
		return this._host;
	}
	set host(host: string) {
		this._host = host;
	}

	get resource(): string {
		return this._resource;
	}
	set resource(resource: string) {
		this._resource = resource;
	}

	get storage(): Storage {
		return this._storage;
	}
	set storage(storage: Storage) {
		this._storage = storage;
	}
}
