import { TBlueprintRoute } from "blueprint/types/blueprint";
import { blueprint as applications } from "./applications";
import { blueprint as banners } from "./banners";
import { blueprint as benefits } from "./benefits";
import { blueprint as categories } from "./categories";
import { blueprint as commerces } from "./commerces";
import { blueprint as departments } from "./departments";
import { blueprint as establishments } from "./establishments";
import { blueprint as users } from "./users";
import { blueprint as codes } from "./codes";
import { blueprint as memberships } from "./memberships";
import { blueprint as commerceUsers } from "./commerces-users";
import { blueprint as usersImportNic } from "./users-import-nic";

export const blueprints: TBlueprintRoute[] = [
	...applications,
	...banners,
	...benefits,
	...categories,
	...commerces,
	...departments,
	...establishments,
	...users,
	...codes,
	...memberships,
	...commerceUsers,
	...usersImportNic,
];
