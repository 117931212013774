import { useContext, useMemo } from "react";
import { context, Context } from "../context";
import { IDataProvider } from "../types/data-provider";

export function useDataProvider(resource: string): IDataProvider<any, any> {
	const ctx = useContext<Context>(context);
	return useMemo<IDataProvider<any, any>>(() => {
		const provider = new ctx.dataProvider(ctx.urlApi);
		provider.resource = resource;
		return provider;
	}, [resource, ctx.urlApi]);
}
