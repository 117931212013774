/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "admin/components/MDBox";
import MDInput from "admin/components/MDInput";
import MDBadge from "admin/components/MDBadge";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "admin/examples/Breadcrumbs";
import NotificationItem from "admin/examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu,
} from "admin/examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
	useMaterialUIController,
	setTransparentNavbar,
	setMiniSidenav,
	setOpenConfigurator,
} from "admin/context-theme";

// Declaring prop types for DashboardNavbar
interface Props {
	absolute?: boolean;
	light?: boolean;
	isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
	const [navbarType, setNavbarType] = useState<"fixed" | "absolute" | "relative" | "static" | "sticky">();
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
	const route = useLocation().pathname.split("/").slice(1);

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType("sticky");
		} else {
			setNavbarType("static");
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
		}

		/**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
		window.addEventListener("scroll", handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () => window.removeEventListener("scroll", handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

	// Styles for the navbar icons
	const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }: { palette: any; functions: any }) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			}

			return colorValue;
		},
	});

	return (
		<AppBar
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
		>
			<Toolbar sx={navbarContainer}>
				<MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
					<Breadcrumbs
						icon="home"
						title={route[route.length - 1]}
						route={route}
						light={light}
						backOption={route.length > 2}
					/>
					<IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
						<Icon fontSize="medium" sx={iconsStyle}>
							{miniSidenav ? "menu_open" : "menu"}
						</Icon>
					</IconButton>
				</MDBox>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						<MDBox color={light ? "white" : "inherit"}>
							<Link to="/authentication/sign-in/basic">
								<IconButton sx={navbarIconButton} size="small" disableRipple>
									<Icon sx={iconsStyle}>account_circle</Icon>
								</IconButton>
							</Link>
							<IconButton size="small" disableRipple color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
								<Icon sx={iconsStyle} fontSize="medium">
									{miniSidenav ? "menu_open" : "menu"}
								</Icon>
							</IconButton>
							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarIconButton}
								onClick={handleConfiguratorOpen}
							>
								<Icon sx={iconsStyle}>settings</Icon>
							</IconButton>
						</MDBox>
					</MDBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

export default DashboardNavbar;
