import produce from "immer";
import { InitialState } from "./initial-state";
import { EAction } from "./action";

export type TAction = { type: EAction; payload: any };

export function reducer(state: InitialState, action: TAction): InitialState {
	switch (action.type) {
		case EAction.SET_AUTH_PROVIDER:
			return produce(state, (draft) => {
				draft.authProvider = action.payload;
			});
		case EAction.SET_DATA_PROVIDER:
			return produce(state, (draft) => {
				draft.dataProvider = action.payload;
			});
		case EAction.SET_REMEMBER_ME:
			return produce(state, (draft) => {
				draft.rememberMe = action.payload;
			});
		case EAction.SET_LAYOUT_DASHBOARD:
			return produce(state, (draft) => {
				draft.layout.dashboard = action.payload;
			});
		case EAction.SET_LAYOUT_LOGIN:
			return produce(state, (draft) => {
				draft.layout.login = action.payload;
			});
		case EAction.SET_LAYOUT_REGISTER:
			return produce(state, (draft) => {
				draft.layout.register = action.payload;
			});
		case EAction.SET_LAYOUT_RESET_PASSWORD:
			return produce(state, (draft) => {
				draft.layout.resetPassword = action.payload;
			});
		case EAction.SET_SESSION:
			return produce(state, (draft) => {
				draft.isAuthentication = true;
				draft.user = action.payload.user;
			});
		case EAction.SET_URL_API:
			return produce(state, (draft) => {
				draft.urlApi = action.payload;
			});
		case EAction.LOGOUT:
			return produce(state, (draft) => {
				draft.user = null;
				draft.isAuthentication = false;
			});
		default:
			return state;
	}
}
