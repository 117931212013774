import React, { useEffect } from "react";
import { RouteObject, useLocation, useRoutes } from "react-router-dom";
import { MakeComponent } from "./makeComponent";
import { TBlueprintRoute } from "./types/blueprint";

function genRoutes(routes: TBlueprintRoute[]): RouteObject[] {
	const routesObject: RouteObject[] = [];

	for (let i = 0; i < routes.length; i++) {
		const route = routes[i];
		if (!route.canActivate || route.canActivate()) {
			routesObject.push({
				path: route.path,
				...(route.caseSensitive ? { caseSensitive: route.caseSensitive } : {}),
				...(route.component ? { element: <MakeComponent {...route.component} /> } : {}),
				...(route.children ? { children: genRoutes(route.children) } : {}),
			});
		}
	}

	return routesObject;
}

export function Routes(props: { routes: TBlueprintRoute[] }) {
	const { pathname } = useLocation();

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	return useRoutes(genRoutes(props.routes));
}
