import { TBlueprintRoute } from "../blueprint/types/blueprint";
import {
	makeComponent,
	makeForm,
	makeInput,
	makeInputReference,
	makeSingleSelect,
	transforms,
} from "./blueprints-utilities";

const fields = [
	makeSingleSelect("role", "Rol del usuario", "role", {
		options: [
			{ value: "USER", label: "USER" },
			{ value: "OWNER", label: "OWNER" },
			{ value: "SUPER_ADMIN", label: "SUPER_ADMIN" },
			{ value: "ADMIN", label: "ADMIN" },
		],
	}),
	makeSingleSelect("customer_quality", "Calidad del Cliente", "customer_quality", {
		options: [
			{ value: "ARRENDADOR", label: "ARRENDADOR" },
			{ value: "ENCARGADO", label: "ENCARGADO" },
			{ value: "PROPIETARIO", label: "PROPIETARIO" },
			{ value: "PAGADOR", label: "PAGADOR" },
		],
	}),
	makeSingleSelect("sex", "Sexo", "sex", {
		options: [
			{ value: "M", label: "MASCULINO" },
			{ value: "F", label: "FEMENINO" },
			{ value: "OT", label: "OTRO" },
		],
	}),
	makeSingleSelect("department_id", "Departamento", "department_id", {
		options: [
			{ value: 1, label: "ATLANTICO" },
			{ value: 2, label: "MAGDALENA" },
			{ value: 3, label: "GUAJIRA" },
		],
	}),
	makeInput("first_name", "First name", "text"),
	makeInput("last_name", "Last name", "text"),
	makeInput("email", "Email", "email"),
	makeInput("password", "Password", "password"),
	makeInput("birthday_date", "Fecha cumpleaños", "date"),
	makeInput("phone", "Phone", "text"),
	makeInput("identification_number", "Identification number", "text"),
	makeInput("nic", "nic", "text"),
	makeInputReference("membership_id", "Membresía", "memberships", "id", "{{name}}", "name"),
];

const editFields = [...fields];
editFields.splice(1, 1);

export const blueprint: TBlueprintRoute[] = [
	{
		path: "users",
		children: [
			{
				path: "",
				component: makeComponent("UserList", {
					title: "Users",
					resource: "users",
					search: true,
					showDeleteConfirmation: true,
					showCreate: true,
					showEdit: true,
					showDelete: true,
					columns: [
						{ Header: "ID", accessor: "id", width: "10%" },
						{ Header: "Rol user", accessor: "role" },
						{ Header: "Last name", accessor: "last_name" },
						{ Header: "First name", accessor: "first_name" },
						{ Header: "Email", accessor: "email" },
						{ Header: "Phone", accessor: "phone" },
						{ Header: "Identification number", accessor: "identification_number" },
						{ Header: "Nic", accessor: "nic" },
						{ Header: "Calidad del cliente", accessor: "customer_quality" },
						{ Header: "Membresía", accessor: "membership_id" },
						{ Header: "Sexo", accessor: "sex" },
						{ Header: "Terms and conditions", accessor: "terms_and_conditions" },
						{ Header: "Created at", accessor: "created_at" },
						{ Header: "action", accessor: "_action", width: "10%" },
					],
					transforms: { ...transforms },
					searchFields: ['email', 'first_name', 'last_name', 'phone', 'identification_number', 'nic'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create User", "users/create", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit User", "users", editFields, true),
			},
		],
	},
];
