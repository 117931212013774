/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";

// Material Dashboard 2 PRO React TS components
import MDBox from "admin/components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "admin/components/MDDropzone/MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "admin/context-theme";
import { styled } from "@mui/material/styles";
import MDTypography from "../MDTypography";
import { btoa } from "buffer";

const PreviewRoot = styled(MDBox)`
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 10px;
	width: 100%;
	height: 100px;
	& .view {
		object-fit: cover;
		height: 80px;
		width: 80px;
		min-width: 80px;
		margin-right: 10px;
		border-radius: 6px;
	}
	& .info {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

interface PreviewProps {
	base: string;
	value: string;
}

const imageHandler = (key: string, w: number = 256, h: number = 256) => {
	return `https://dnfhqmc2wswkq.cloudfront.net/${window.btoa(
		JSON.stringify({
			bucket: "vivesmas-prod-app-origin",
			key: key,
			edits: {
				resize: {
					width: w,
					height: h,
					fit: "inside",
				},
			},
		})
	)}`;
};

function Preview(props: PreviewProps) {
	const name = useMemo(() => props.value.split("/").pop().substring(37), [props.value]);
	return (
		<PreviewRoot>
			{<img src={`${imageHandler(props.value)}`} alt="" className={"view"} />}{" "}
			<MDTypography className={"info"}>{name}</MDTypography>
		</PreviewRoot>
	);
}

// Declaring props types for MDDropzone
export interface MDDropzoneProps {
	baseUrl: string;
	options: DropzoneOptions;
	value: string | string[];
}

function MDDropzone({ baseUrl, options, value }: MDDropzoneProps): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

	return (
		<MDDropzoneRoot ownerState={{ darkMode }} {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<MDBox className="fallback" bgColor="transparent">
					<MDBox>Drop the files here ...</MDBox>
				</MDBox>
			) : (
				(!value || value === "" || value.length === 0) && <MDBox>Drag and drop some files here</MDBox>
			)}
			{Array.isArray(value)
				? value.map((it, i) => <Preview key={i} base={baseUrl} value={it} />)
				: value && <Preview base={baseUrl} value={value} />}
		</MDDropzoneRoot>
	);
}

export default MDDropzone;
