export enum EAction {
	SET_AUTH_PROVIDER,
	SET_DATA_PROVIDER,
	SET_LAYOUT_DASHBOARD,
	SET_LAYOUT_LOGIN,
	SET_LAYOUT_REGISTER,
	SET_LAYOUT_RESET_PASSWORD,
	SET_REMEMBER_ME,
	SET_SESSION,
	SET_URL_API,
	LOGOUT,
}
