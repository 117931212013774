import { TBlueprintRoute } from "../blueprint/types/blueprint";
import {
	makeForm,
	makeInput,
	makeInputFile,
	makeInputReference,
	makeInputSelect,
	makeList,
	makeSingleSelect,
} from "./blueprints-utilities";

const fields = [
	makeInputFile("image", "Imagen", "banners/upload-image"),
	makeInput("title", "Titulo", "text"),
	makeInput("detail", "Descripcion", "textarea"),
	makeSingleSelect("benefit_channel", "Canal de distribución", "benefit_channel", {
		options: [
			{ value: "WEB", label: "Web" },
			{ value: "WHATSAPP", label: "Whatsapp" },
			{ value: "IN_PLACE", label: "En establecimiento" },
			{ value: "UNI_DROGAS", label: "Uni drogas" },
		],
	}),

	makeSingleSelect("source_code", "Origen del código", "source_code", {
		options: [
			{ value: "AUTO_GEN", label: "Automático" },
			{ value: "STATIC", label: "Estático" },
			{ value: "LIST_CODE", label: "Listado" },
			{ value: "NO_CODE", label: "Sin código" },
		],
	}),
	makeSingleSelect("status", "Estado del beneficio", "status", {
		options: [
			{ value: "ACTIVE", label: "ACTIVO" },
			{ value: "INACTIVE", label: "INACTIVO" },
		],
	}),
	makeInput("date_init", "fecha inicio del beneficio", "date"),
	makeInput("expiry", "Fecha de expiración", "date"),
	makeInput("quantity_per_user", "Cantidad por usuario", "number"),
	makeInput("total_quantity", "Cantidad total", "number"),
	makeInput("actual_price", "Precio actual", "number"),
	makeInput("discount_price", "Precio con descuento", "number"),
	makeInput("display_discount", "Descuento", "string"),
	makeInput("terms", "Términos y condiciones", "textarea"),
	makeInput("url", "URL", "textarea"),
	makeInput("whatsapp", "Whatsapp", "string"),
	makeInputReference("category_id", "Categoría", "categories", "id", "{{title}}", "title"),
	makeInputSelect("departments", "Departamento", "departments", "id", "name", { multiple: true }),
	makeInputReference("commerce_id", "Comercio", "commerces", "id", "{{name}}", "name"),
	makeInputReference("membership_id", "Membresía", "memberships", "id", "{{name}}", "name"),
	makeInputReference("id_unidrogas", "ID Uni Drogas", "benefits", "id_unidrogas", "{{id_unidrogas}}", "id_unidrogas"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "benefits",
		children: [
			{
				path: "",
				component: makeList(
					"Benefits",
					"benefits",
					[
						{ Header: "ID", accessor: "id", width: "10%" },
						{ Header: "Titulo", accessor: "title" },
						{ Header: "Origen del código", accessor: "source_code" },
						{ Header: "Precio actual", accessor: "actual_price" },
						{ Header: "Precio anterior", accessor: "discount_price" },
						{ Header: "Descuento", accessor: "display_discount" },
						{ Header: "Fecha de expiración", accessor: "expiry" },
						{ Header: "Cantidad por usuario", accessor: "quantity_per_user" },
						{ Header: "action", accessor: "_action", width: "10%" },
					],
					{
						showDownloadCsv: true,
						searchFields: ["title"],
					}
				),
			},
			{
				path: "create",
				component: makeForm("Create Benefit", "benefits", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Benefit", "benefits", fields, true),
			},
		],
	},
];
