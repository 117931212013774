import React from "react";
import { Dashboard, InitialState, initialState, Login, Register, ResetPassword } from "./initial-state";

export interface Context extends InitialState {
	setAuthProvider: (authProvider: any) => void;
	setDataProvider: (dataProvider: any) => void;
	setLayoutDashboard: (payload: Dashboard) => void;
	setLayoutLogin: (payload: Login) => void;
	setLayoutRegister: (payload: Register) => void;
	setLayoutResetPassword: (payload: ResetPassword) => void;
	setRememberMe: (rememberMe: boolean) => void;
	setSession: (user: any) => void;
	setUrlApi: (url: string) => void;
	logout: () => void;
}

export const context = React.createContext<any>(initialState);
