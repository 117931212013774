import React, { forwardRef, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import MDBox from "admin/components/MDBox";
import { Label } from "../Input/label";
import { useDataProvider } from "../../hooks/useDataProvider";

type Props = {
	label: string;
	value: any;
	resource: string;
	resourceLabel: string;
	resourceValue: string;
} & { [k: string]: any };

const colourStyles: StylesConfig = {
	control: (styles) => ({ ...styles, backgroundColor: "white", fontSize: "0.8rem" }),
	option: (styles, { isDisabled }) => ({
		...styles,
		fontSize: "0.8rem",
		textTransform: "capitalize",
		cursor: isDisabled ? "not-allowed" : "default",
	}),
	menu: (styles) => ({ ...styles, zIndex: 999 }),
	placeholder: (styles) => ({ ...styles, fontSize: "0.9rem" }),
	multiValueLabel: (styles) => ({ ...styles, fontSize: "0.8rem" }),
	input: (styles) => ({ ...styles, fontSize: "0.8rem", height: 40 }),
};

export const InputSelect = forwardRef<any, any>((props: Props, ref) => {
	const { label, value, resource, resourceLabel, resourceValue } = props;
	const [options, setOptions] = useState<{ value: string; label: string }[]>(props.options || []);
	const [load, setLoad] = useState<number>(0);

	const dataProvider = useDataProvider(resource);

	useEffect(() => {
		if (options.length > 0) return;
		const where = load > 0 ? { [resourceLabel]: { $iLike: `%${value}%` } } : {};
		dataProvider.find({ where, pagination: { page: 1, perPage: 10 } }).then(({ data }) => {
			setOptions(
				data.map((it) => ({
					value: it[resourceValue],
					label: it[resourceLabel],
				}))
			);
		});
	}, []);

	useEffect(() => {
		console.log("value", value);
	}, [value]);

	return (
		<MDBox mb={2} position={"relative"}>
			{label && <Label label={label} />}
			<Select
				ref={ref}
				options={options}
				styles={colourStyles}
				placeholder={`${label}`}
				value={props.value}
				onChange={(values: any | any[]) => {
					props.onChange({ target: { name: props.name, value: values } });
				}}
				isMulti={props.multiple}
			/>
		</MDBox>
	);
});
