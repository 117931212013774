import React from "react";

import { AdminProvider } from "admin";
import menu from "./menu";
import { blueprints } from "./pages";

export default function App() {
	return (
		<AdminProvider
			layout={{
				dashboard: { path: "dashboard", initPath: "users" },
				login: { path: "sing-in" },
			}}
			menu={menu}
			routes={blueprints}
			urlApi={process.env.REACT_APP_URL}
		/>
	);
}
