import React from "react";
import Card from "@mui/material/Card";
import MDBox from "admin/components/MDBox";
import MDButton from "admin/components/MDButton";
import MDTypography from "admin/components/MDTypography";
import DashboardLayout from "admin/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "admin/examples/Navbars/DashboardNavbar";
import MDSnackbar from "../../../components/MDSnackbar";
import { TBlueprintComponent } from "../../../../blueprint/types/blueprint";
import { MakeComponent } from "../../../../blueprint/makeComponent";
import { useForm } from "./useForm";

interface FormProps {
	resource: string;
	isEdit?: boolean;
	fields: TBlueprintComponent[];
	title: string;
}

function Form(props: FormProps) {
	const { values, setValues, onSubmit, errorSB, errorMessage, closeErrorSB } = useForm(props);

	const fields = props.fields.map(({ name, props }) => ({
		name,
		props: {
			...props,
			"data-record": values,
			fullWidth: true,
			value: values[props.name],
			onChange: (e: any) => {
				console.log(e.target.value);
				setValues((prev) => ({ ...prev, [props.name]: e.target.value }));
			},
		},
	}));

	const renderErrorSB = (
		<MDSnackbar
			color="error"
			icon="warning"
			title={props.title}
			content={errorMessage}
			dateTime=""
			open={errorSB}
			onClose={closeErrorSB}
			close={closeErrorSB}
			bgWhite
		/>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MakeComponent
				name={"MDBox"}
				props={{ py: 3 }}
				children={[
					{
						name: "Card",
						props: { sx: { p: 3, m: "0 auto", maxWidth: "600px" } },
						children: [
							{
								name: "MDBox",
								props: { mb: 2 },
								children: [{ name: "MDTypography", props: { variant: "title", children: props.title } }],
							},
							{
								name: "MDBox",
								props: {
									component: "form",
									role: "form",
									onSubmit: (e: React.FormEvent<HTMLDivElement>) => {
										e.preventDefault();
										onSubmit(values);
									},
								},
								children: [
									...fields,
									{
										name: "MDButton",
										props: {
											color: "dark",
											fullWidth: true,
											type: "submit",
											variant: "gradient",
											children: props.isEdit ? "Save" : "Create",
										},
									},
								],
							},
						],
					},
				]}
			/>
			{renderErrorSB}
		</DashboardLayout>
	);
}

export default Form;
