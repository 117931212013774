import { TBlueprintRoute } from "../blueprint/types/blueprint";
import {
	makeForm,
	makeInput,
	makeInputFile,
	makeInputReference,
	makeInputSelect,
	makeList,
} from "./blueprints-utilities";

const fields = [makeInput("name", "Nombre", "text"), makeInput("level", "Nivel", "number")];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "memberships",
		children: [
			{
				path: "",
				component: makeList("memberships", "memberships", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Titulo", accessor: "name" },
					{ Header: "Nivel", accessor: "level" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['name'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create membership", "memberships", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit membership", "memberships", fields, true),
			},
		],
	},
];
