import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeList } from "./blueprints-utilities";

const fields = [makeInput("name", "Nombre", "text")];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "departments",
		children: [
			{
				path: "",
				component: makeList("Departments", "departments", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Nombre", accessor: "name" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['name'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create Department", "departments", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Department", "departments", fields, true),
			},
		],
	},
];
