import { Subject } from "rxjs";
import { Options, Row, useList } from "../admin/layouts/dashboards/list/useList";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import MDSnackbar from "../admin/components/MDSnackbar";
import { MakeComponent } from "../blueprint/makeComponent";
import { navbarIconButton } from "../admin/examples/Navbars/DashboardNavbar/styles";
import { parse } from "json2csv";
import DashboardLayout from "../admin/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../admin/examples/Navbars/DashboardNavbar";
import { context, Context } from "../admin/context";
import { IDataProvider } from "../admin/types/data-provider";

function download(filename: string, text: string) {
	let element = document.createElement("a");
	element.setAttribute("href", "data:text/csv;charset=iso-8859-1," + encodeURIComponent(text));
	element.setAttribute("download", filename);

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

function upload(chunks: number = 0, fields: string[] = []) {
	const subject = new Subject();
	const element = document.createElement("input");
	element.setAttribute("type", "file");
	element.setAttribute("accept", "text/csv");

	element.onchange = (e: any) => {
		const file: File = e.target.files[0];
		const header: string[] = [];
		const rows: { [k: string]: string }[] = [];
		file
			.text()
			.then((text: string) => {
				try {
					text.split("\n").forEach((line: string, i: number) => {
						line = line.trim();
						if (line) {
							const pieces = line.split(/[,;]/gi).map((it) => it.trim());
							if (i === 0) {
								header.push(...pieces);
							} else {
								const row: any = {};
								for (let i = 0; i < pieces.length; i++) {
									row[header[i]] = pieces[i];
								}
								rows.push(row);
							}
						}
					});
				} catch (e) {
					subject.error(e);
				}
				const getFields = (data: any[]) => {
					if (!fields.length) {
						return data;
					} else {
						return data.map((it) => {
							const row: any = {};
							for (let key in it) {
								if (fields.includes(key)) {
									row[key] = it[key];
								}
							}
							return row;
						});
					}
				};
				if (chunks > 0) {
					const chunked = [];
					for (let i = 0; i < rows.length; i += chunks) {
						chunked.push(getFields(rows.slice(i, i + chunks)));
					}
					subject.next(chunked);
				} else {
					subject.next(getFields(rows));
				}
				subject.complete();
			})
			.catch((error) => subject.error(error));
	};

	element.style.display = "none";

	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);

	return subject;
}

type Props = {
	actions?: ((row: Row) => JSX.Element)[];
	columns: { Header: string; accessor: string; width: string }[];
	description?: string;
	search: boolean;
	showCreate?: boolean;
	showDownloadCsv?: boolean;
	showImportCsv?: boolean;
	showEdit?: boolean;
	showDelete?: boolean;
	title: string;
} & Options;

function List(props: Props) {
	const ctx = useContext<Context>(context);
	const { errorSB, errorMessage, closeErrorSB, bulkCreate, bulkProcess, remove, rows, total, getData, getRawData } = useList(props);
	const [perPage, setPerPage] = React.useState(10);
	const nav = useNavigate();
	const [updateMembershipProcess, setUpdateMembershipProcess] = useState(0);
	const keyButton = useMemo<string>(() => uuid(), [props.resource]);

	const dataProvider = useMemo<IDataProvider<any, any>>(() => {
		const provider = new ctx.dataProvider(ctx.urlApi);
		provider.resource = props.resource;
		return provider;
	}, [props.resource, ctx.urlApi]);

	const updateMembership = useCallback(
		async (data: any[]) => {
			for (let i = data.length - 1; i >= 0; i--) {
				await dataProvider.rawPost("memberships", data[i]).catch((error) => {
					setUpdateMembershipProcess(0);
					throw new Error("Error creating code");
				});
				setUpdateMembershipProcess(100 - (i / data.length) * 100);
			}
			setUpdateMembershipProcess(0);
		},
		[dataProvider, props.resource, props.transforms]
	);

	const importNic = useCallback(
		async (data: any[]) => {
			for (let i = data.length - 1; i >= 0; i--) {
				await dataProvider.rawPost("users-import-nic", data[i]).catch((error) => {
					setUpdateMembershipProcess(0);
					throw new Error("Error creating code");
				});
				setUpdateMembershipProcess(100 - (i / data.length) * 100);
			}
			setUpdateMembershipProcess(0);
		},
		[dataProvider, props.resource, props.transforms]
	);

	const renderErrorSB = (
		<MDSnackbar
			color="error"
			icon="warning"
			title={props.title}
			content={errorMessage}
			dateTime=""
			open={errorSB}
			onClose={closeErrorSB}
			close={closeErrorSB}
			bgWhite
		/>
	);

	const csvExportButton = (
		<MakeComponent
			key={`csvExportButton-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick: async () => {
					const result: any[] = [];
					let next = true;
					let page = 1;
					while (next) {
						const { data } = await getRawData(1000, page);
						result.push(...data);
						page ++;
						next = data.length > 0;
					}
					const csv = parse(result, { fields: props.columns.map((c) => c.accessor) });
					download(`${props.title}.csv`, csv);
				},
			}}
			children={[{ name: "Icon", props: { children: "download" } }]}
		/>
	);

	const csvImportButton = (
		<MakeComponent
			key={`csvImportButtonn-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick() {
					upload().subscribe((data: any) => {
						bulkCreate(data as any[]).then();
					});
				},
			}}
			children={[{ name: "Icon", props: { children: "upload" } }]}
		/>
	);

	const updateMembershipBtn = (
		<MakeComponent
			key={`updateMembership-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick() {
					upload(5000, ["CUENTA", "CATEGORIA_CLIENTE"]).subscribe((data: any) => {
						updateMembership(data).then();
					});
				},
			}}
			children={[{ name: "Icon", props: { children: "upload" } }]}
		/>
	);

	const importNicUser = (
		<MakeComponent
			key={`importNicUser-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick() {
					upload(5000, ["CUENTA"]).subscribe((data: any) => {
						importNic(data).then();
					});
				},
			}}
			children={[{ name: "Icon", props: { children: "upload" } }]}
		/>
	);
	const exportUserBtn = (
		<MakeComponent
			key={`updateMembership-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick() {
					upload(5000, ["CUENTA", "CATEGORIA_CLIENTE"]).subscribe((data: any) => {
						updateMembership(data).then();
					});
				},
			}}
			children={[{ name: "Icon", props: { children: "upload" } }]}
		/>
	);

	const createButton = (
		<MakeComponent
			key={`createButton-${keyButton}`}
			name={"MDButton"}
			props={{
				color: "info",
				onClick() {
					nav("create");
				},
				children: "create",
			}}
		/>
	);

	const editButton = ({ id }: Row) => (
		<MakeComponent
			key={`editButton-${keyButton}-${id}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "inherit",
				sx: navbarIconButton,
				onClick() {
					nav(`edit/${id}`);
				},
			}}
			children={[{ name: "Icon", props: { children: "edit" } }]}
		/>
	);

	const deleteButton = ({ id }: Row) => (
		<MakeComponent
			key={`deleteButton-${keyButton}-${id}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "error",
				sx: navbarIconButton,
				onClick() {
					// noinspection JSIgnoredPromiseFromCall
					remove(id);
				},
			}}
			children={[{ name: "Icon", props: { children: "delete" } }]}
		/>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MakeComponent
				name={"MDBox"}
				props={{ py: 3 }}
				children={[
					{
						name: "Card",
						props: {
							sx: {
								overflow: "hidden",
								position: "relative",
							},
						},
						children: [
							{ name: "loading", props: { bulkProcess } },
							{ name: "loading", props: { bulkProcess: updateMembershipProcess } },
							{
								name: "MDBox",
								props: {
									px: 3,
									pt: 3,
									pb: 3,
									lineHeight: 1,
								},
								children: [
									{ name: "MDTypography", props: { variant: "h5", fontWeight: "medium", children: props.title } },
									{ name: "MDTypography", props: { variant: "button", color: "text", children: props.description } },
								],
							},
							{
								name: "DataTable",
								props: {
									actions: [
										updateMembershipBtn,
										csvExportButton,
										props.showImportCsv && csvImportButton,
										props.showDownloadCsv && csvExportButton,
										props.showCreate && createButton,
									],
									canSearch: props.search,
									pageCount: Math.ceil(total / perPage),
									onPageChange: (page: number) => getData(perPage, page),
									onSearch: (search: string) => getData(perPage, 1, search),
									setPerPage,
									table: {
										columns: props.columns,
										rows: rows.map((it) => ({
											...it,
											_action: [
												props.showEdit && editButton(it),
												props.showDelete && deleteButton(it),
												...(props.actions || []).map((action) => action(it)),
											],
										})),
									},
								},
							},
						],
					},
				]}
			/>
			{renderErrorSB}
		</DashboardLayout>
	);
}

export default List;
