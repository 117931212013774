import React, { forwardRef, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "admin/components/MDBox";
import MDInput, { MDInputProps } from "admin/components/MDInput";
import { Label } from "../Input/label";

interface InputReferenceProps extends MDInputProps {
	options: { value: string; label: string; default: boolean }[];
}

export const SingleSelect = forwardRef((props: InputReferenceProps, ref) => {
	const { label, ...propsInput } = props;
	const [options] = useState(props.options);

	const inputValue = useMemo(() => {
		const option = options.find((it) => it.value === propsInput.value);
		return option ? option.label : (options.find((it) => it.default) || {}).label || "";
	}, [options, propsInput.value]);

	return (
		<MDBox mb={2} position={"relative"}>
			{label && <Label label={label} />}
			<Autocomplete
				ref={ref}
				fullWidth
				disableClearable
				value={inputValue}
				options={options.map((it) => it.label)}
				onChange={(event: any, newValue: any) => {
					propsInput.onChange({
						...event,
						target: {
							...event.target,
							name: propsInput.name,
							value: options.find((it) => it.label === newValue).value,
						},
					});
				}}
				size="medium"
				renderInput={(params) => <MDInput {...params} />}
			/>
		</MDBox>
	);
});
