import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { context, Context } from "admin/context";
import { AuthProvider } from "admin/providers/auth";

export function useLogin() {
	const ctx = useContext<Context>(context);
	const [userFields, setUserFieldsField] = useState({ email: "", password: "" });

	const authProvider = useMemo<AuthProvider<any>>(() => new ctx.authProvider(ctx.urlApi), []);

	const handleSubmit = useCallback((values: { email: string; password: string }) => {
		authProvider.login(values.email, values.password).then((user) => {
			ctx.setSession(user);
		});
	}, []);

	const handleSetRememberMe = useCallback(() => {
		const rememberMe = !ctx.rememberMe;
		authProvider.storage = rememberMe ? localStorage : sessionStorage;
		ctx.setRememberMe(rememberMe);
	}, [ctx.rememberMe, ctx.setRememberMe]);

	useEffect(() => {
		authProvider.checkLogIn().then((user) => {
			ctx.setSession(user);
		});
	}, []);

	return {
		fields: userFields,
		handleSubmit,
		handleSetRememberMe,
		isAuthentication: ctx.isAuthentication,
		setFields: setUserFieldsField,
	};
}

export function useCheckLogin() {
	const ctx = useContext<Context>(context);
	const [loading, setLoading] = useState(true);

	const authProvider = useMemo<AuthProvider<any>>(() => new ctx.authProvider(ctx.urlApi), []);

	useEffect(() => {
		authProvider
			.checkLogIn()
			.then((user) => {
				ctx.setSession(user);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	return {
		loading,
		isAuthentication: ctx.isAuthentication,
	};
}
