import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeInputReference, makeList } from "./blueprints-utilities";

const fields = [
	makeInput("name", "Name", "text"),
	makeInput("address", "Address", "text"),
	makeInput("latitude", "Latitude", "number"),
	makeInput("longitude", "Longitude", "number"),
	makeInputReference("membership_id", "Membership", "memberships", "id", "{{name}}", "name"),
	makeInputReference("commerce_id", "Commerce", "commerces", "id", "{{name}}", "name"),
	makeInputReference("department_id", "Department", "departments", "id", "{{name}}", "name"),
];
export const blueprint: TBlueprintRoute[] = [
	{
		path: "establishments",
		children: [
			{
				path: "",
				component: makeList("Establishments", "establishments", [
					{ Header: "ID", accessor: "id", width: "10%" },
					{ Header: "Name", accessor: "name" },
					{ Header: "action", accessor: "_action", width: "10%" },
				], {
					searchFields: ['name'],
				}),
			},
			{
				path: "create",
				component: makeForm("Create Establishments", "establishments", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Establishments", "establishments", fields, true),
			},
		],
	},
];
