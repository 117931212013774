import { Subject } from "rxjs";

export function getFields(fields: string[], data: any[]): any[] {
	if (!fields.length) {
		return data;
	}
	return data.map((row) => {
		const result: any = {};
		fields.forEach((field) => {
			result[field] = row[field];
		});
		return result;
	});
}

export function processCSV(file: File) {
	const subject = new Subject<any[]>();

	file
		.text()
		.then((text: string) => {
			const lines = text.split("\n");
			const headers = lines[0]
				.trim()
				.split(/[,;]/gi)
				.map((it) => it.trim());
			const data = lines.slice(1).map((line) => {
				const row: { [k: string]: string } = {};
				line.split(/[,;]/gi).forEach((value, index) => {
					if (line) row[headers[index]] = value.trim();
				});
				return row;
			});
			subject.next(data);
			subject.complete();
		})
		.catch((error) => subject.error(error));

	return subject.asObservable();
}

export function upload(chunkSize: number = 0, fields: string[] = []) {
	const subject = new Subject<any[]>();
	const element = document.createElement("input");
	element.setAttribute("type", "file");
	element.setAttribute("accept", "text/csv");

	element.onchange = (e: any) => {
		const file: File = e.target.files[0];
		processCSV(file).subscribe({
			next: (data) => {
				if (chunkSize) {
					const chunks: any[] = [];
					for (let i = 0; i < data.length; i += chunkSize) {
						chunks.push(getFields(fields, data.slice(i, i + chunkSize)));
					}
					subject.next(chunks);
				} else {
					subject.next(getFields(fields, data));
				}
			},
			error: (error) => subject.error(error),
		});
	};

	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);

	return subject.asObservable();
}
