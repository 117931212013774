import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeForm, makeInput, makeInputReference, makeList } from "./blueprints-utilities";

const fields = [
	makeInput("code", "Code", "text"),
	makeInputReference("benefit_id", "Beneficio", "benefits", "id", "{{id}} - {{commerce.name}} - {{title}}", "title"),
];

export const blueprint: TBlueprintRoute[] = [
	{
		path: "benefits-codes",
		children: [
			{
				path: "",
				component: makeList(
					"Codes",
					"benefits-codes",
					[
						{ Header: "ID", accessor: "id", width: "10%" },
						{ Header: "Code", accessor: "code" },
						{ Header: "Status", accessor: "status" },
						{ Header: "Benefit", accessor: "benefit_id" },
						{ Header: "action", accessor: "_action", width: "10%" },
					],
					{
						showImportCsv: true,
						searchFields: ["code", "status"],
					}
				),
			},
			{
				path: "create",
				component: makeForm("Create Code", "benefits-codes", fields),
			},
			{
				path: "edit/:id",
				component: makeForm("Edit Code", "benefits-codes", fields, true),
			},
		],
	},
];
