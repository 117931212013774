/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled, Theme } from "@mui/material/styles";

export default styled("div")(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
	const { palette, typography, borders, functions } = theme;
	const { darkMode } = ownerState;

	const { text, white, dark, inputBorderColor, grey } = palette;
	const { size } = typography;
	const { borderRadius, borderWidth } = borders;
	const { rgba } = functions;

	return {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "100px",
		border: `${borderWidth[1]} solid ${inputBorderColor} !important`,
		borderRadius: borderRadius.md,
		backgroundColor: darkMode ? dark.main : grey[50],
		cursor: "pointer",
		"&, & *": {
			color: darkMode ? white.main : text.primary,
		},
		"& .fallback": {
			position: "absolute",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backdropFilter: "blur(2px)",
		},
	};
});
