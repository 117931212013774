/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "admin/components/MDBox";
import MDTypography from "admin/components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "admin/examples/Navbars/DefaultNavbar";
import PageLayout from "admin/examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "admin/context-theme";

// Declaring props types for IllustrationLayout
interface Props {
	header?: ReactNode;
	title?: string;
	description?: string;
	children: ReactNode;
	illustration?: string;
}

function IllustrationLayout({ header, title, description, illustration, children }: Props): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<PageLayout background="white">
			<Grid
				container
				sx={{
					backgroundColor: ({ palette: { background, white } }) => (darkMode ? background.default : white.main),
				}}
			>
				<Grid item xs={12} lg={6}>
					<MDBox
						display={{ xs: "none", lg: "flex" }}
						width="calc(100% - 2rem)"
						height="calc(100vh - 2rem)"
						borderRadius="lg"
						ml={2}
						mt={2}
						sx={{ backgroundImage: `url(${illustration})` }}
					/>
				</Grid>
				<Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
					<MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
						<MDBox py={3} px={3} textAlign="center">
							{!header ? (
								<>
									<MDBox mb={1} textAlign="center">
										<MDTypography variant="h4" fontWeight="bold">
											{title}
										</MDTypography>
									</MDBox>
									<MDTypography variant="body2" color="text">
										{description}
									</MDTypography>
								</>
							) : (
								header
							)}
						</MDBox>
						<MDBox p={3}>{children}</MDBox>
					</MDBox>
				</Grid>
			</Grid>
		</PageLayout>
	);
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
	header: "",
	title: "",
	description: "",
	illustration: "",
};

export default IllustrationLayout;
