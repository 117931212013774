import React, { useCallback, useEffect, useState } from "react";
import { DropzoneOptions, FileWithPath } from "react-dropzone";
import MDBox from "admin/components/MDBox";
import { MDInputProps } from "admin/components/MDInput";
import MDDropzone from "admin/components/MDDropzone";
import { useS3InputFile } from "./useS3InputFile";
import { Label } from "./label";

export interface InputFileProps extends MDInputProps {
	baseUrl: string;
	signUrl: string;
	value: string | string[];
	onChange: (t: any) => void;
	options?: Omit<DropzoneOptions, "onDrop">;
}

export function InputFile(props: InputFileProps) {
	const { signUrl, options, label } = props;
	const [paths, setPaths] = useState<{ [p: string]: string }>({});
	const { uploadFile } = useS3InputFile(signUrl);

	const onDrop = useCallback(
		(fs: FileWithPath[]) => {
			const prevPaths = Object.keys(paths);
			for (let i = 0; i < fs.length; i++) {
				if (!prevPaths.includes(fs[i].path)) {
					uploadFile(fs[i]).then((data) => {
						if (options?.multiple) {
							setPaths((p) => ({ ...p, [fs[i].path]: data.path }));
						} else {
							setPaths({ [fs[i].path]: data.path });
						}
					});
				}
			}
		},
		[paths, setPaths, uploadFile, options]
	);

	useEffect(() => {
		const files = Object.values(paths);
		if (options?.multiple) {
			props.onChange({ target: { name: props.name, value: files } });
		} else {
			props.onChange({ target: { name: props.name, value: files[0] || "" } });
		}
	}, [paths, options]);

	return (
		<MDBox mb={2}>
			{label && <Label label={label} />}
			<MDDropzone baseUrl={props.baseUrl} value={props.value || ""} options={{ onDrop, multiple: false, ...options }} />
		</MDBox>
	);
}
