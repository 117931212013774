import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const Container = styled("div")({
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "rgba(255, 255, 255, 0.5)",
	zIndex: 1000,
});


export const Loading = ({ bulkProcess }: any) => {
	console.log("bulkCreate", bulkProcess);
	return bulkProcess > 0 ? (
		<Container>
			<Box>
				<CircularProgress color="inherit" />
				<div>{bulkProcess.toFixed(1)}</div>
			</Box>
		</Container>
	) : null;
};
