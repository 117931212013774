import MDAvatar from "admin/components/MDAvatar";

import {
	Person as PersonIcon,
	StoreTwoTone,
	Category,
	Discount,
	BrokenImage,
	Apps,
	Abc,
	CardMembership,
	LocationOn,
	AddBusiness,
	VerifiedUser,
} from "@mui/icons-material";

import { SidebarItem } from "./admin/types/sidebarItem";
const menu: SidebarItem[] = [
	{
		type: "collapse",
		name: "{{user.first_name}} {{user.last_name}}",
		key: "avatar",
		icon: <MDAvatar size="sm" />,
		collapse: [
			{
				name: "Logout",
				key: "logout",
				route: "/logout",
			},
		],
	},
	{ type: "divider", key: "divider-0" },
	{
		type: "collapse",
		name: "Applications",
		key: "applications",
		icon: <Apps />,
		route: "/dashboard/applications",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Banners",
		key: "banners",
		icon: <BrokenImage />,
		route: "/dashboard/banners",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Benefits",
		key: "benefits",
		icon: <Discount />,
		route: "/dashboard/benefits",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Categories",
		key: "categories",
		icon: <Category />,
		route: "/dashboard/categories",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Codes",
		key: "benefits-codes",
		icon: <Abc />,
		route: "/dashboard/benefits-codes",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Departments",
		key: "departments",
		icon: <LocationOn />,
		route: "/dashboard/departments",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Commerces",
		key: "commerces",
		icon: <StoreTwoTone />,
		route: "/dashboard/commerces",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Establishments",
		key: "establishments",
		icon: <AddBusiness />,
		route: "/dashboard/establishments",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Memberships",
		key: "memberships",
		icon: <CardMembership />,
		route: "/dashboard/memberships",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Users",
		key: "users",
		icon: <PersonIcon />,
		route: "/dashboard/users",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Users Import Nic",
		key: "users-import-nic",
		icon: <PersonIcon />,
		route: "/dashboard/users-import-nic",
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Commerce Owners",
		key: "commerces-users",
		icon: <VerifiedUser />,
		route: "/dashboard/commerces-owners",
		noCollapse: true,
	},
];

export default menu;
