import { Fragment } from "react";
import { TBlueprintComponent } from "./types/blueprint";
import { getComponent } from "./resources/components";

export function MakeComponent({ children, name, props }: TBlueprintComponent) {
	const { resource, type } = getComponent(name);
	const FC = resource;
	return (
		<Fragment>
			{type !== "Component" ? (
				resource
			) : children ? (
				<FC {...props}>
					{children.map((it, i) => (
						<MakeComponent key={i} {...it} />
					))}
				</FC>
			) : (
				<FC {...props} />
			)}
		</Fragment>
	);
}
