import axios, { AxiosInstance } from "axios";
import { IAuthProvider } from "../types/auth-provider";

export class AuthProvider<E> implements IAuthProvider<E> {
	private _host: string;
	private _storage: Storage = localStorage;
	private axios: AxiosInstance;

	constructor(host: string) {
		this.host = host;
		this.makeAxios();
	}

	private makeAxios() {
		this.axios = axios.create({
			baseURL: this.host,
			timeout: 5000,
		});
	}

	checkLogIn(): Promise<E> {
		return this.getEntity();
	}

	getEntity(): Promise<E> {
		const token: string = this.storage.getItem("jwt") || localStorage.getItem("jwt");
		if (token) {
			return this.axios
				.get("/auth/profile", { headers: { Authorization: `Bearer ${token}` } })
				.then(({ data }) => data);
		} else {
			return Promise.reject({ message: "Not Authenticated" });
		}
	}

	login(username: string, password: string): Promise<E> {
		return this.axios.post("/auth/login", { email: username, password: password }, {headers: {"application_id": 1}}).then(({ data }) => {
			this.storage.setItem("jwt", data.access_token);
			return data.user;
		});
	}

	logout(): Promise<any> {
		this._storage.clear();
		localStorage.clear();
		sessionStorage.clear();
		return Promise.resolve(undefined);
	}

	onError(error: any): void {}

	/*
	 * GETTERS AND SETTERS
	 */

	get host(): string {
		return this._host;
	}
	set host(host: string) {
		this._host = host;
	}

	get storage(): Storage {
		return this._storage;
	}
	set storage(storage: Storage) {
		this._storage = storage;
	}
}
