import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { TBlueprintComponent } from "../../../../blueprint/types/blueprint";
import { useDataProvider } from "../../../hooks/useDataProvider";

export type Options = {
	defaultValues?: {};
	isEdit?: boolean;
	fields: TBlueprintComponent[];
	resource: string;
};

export function useForm(props: Options) {
	const nav = useNavigate();
	const params = useParams<{ id: string }>();
	const [values, setValues] = useState<{ [p: string]: any }>(props.defaultValues || {});
	const [errorSB, setErrorSB] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const dataProvider = useDataProvider(props.resource);

	const openErrorSB = () => setErrorSB(true);
	const closeErrorSB = () => setErrorSB(false);

	const onSubmit = useCallback((values: { [p: string]: any }) => {
		const res = props.isEdit ? dataProvider.updateOne(+params.id, values) : dataProvider.create(values);
		res.then(() => nav(-1)).catch((error) => {
			setErrorMessage(error.response?.data?.message || error.message);
			openErrorSB();
		});
	}, [props.isEdit, dataProvider]);

	useEffect(() => {
		if (props.isEdit) dataProvider.get(+params.id).then((data) => {
			setValues(_.merge({}, ...props.fields.map(({ props: { name, resourceLabel, resourceValue } }) => {
				if (resourceLabel && resourceValue && Array.isArray(data[name]) && data[name].length > 0) {
					return { [name]: data[name].map((item: any) => ({ value: item[resourceValue], label: item[resourceLabel] })) };
				}
				const date = moment(["expiry", "date_init"].includes(name) ? data[name] : "invalid");
				return { [name]: date.isValid() ? date.format("YYYY-MM-DD") : data[name] };
			})));
		});
	}, [props.isEdit, params.id, dataProvider]);

	return { values, setValues, onSubmit, errorSB, errorMessage, openErrorSB, closeErrorSB };
}
