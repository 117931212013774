import moment from "moment";
import { TBlueprintComponent } from "blueprint/types/blueprint";

const transformDate = (x: any) => moment(x).format("MM/DD/YYYY");

const cloudFront = "https://d1naeumwt4g7j4.cloudfront.net";

export const transforms = {
	created_at: transformDate,
	updated_at: transformDate,
	deleted_at: transformDate,
};

export const makeComponent = (c: string, props: any, children?: TBlueprintComponent[]) => ({
	name: c,
	children,
	props,
});

export const makeList = (title: string, resource: string, columns: any[], props?: any) =>
	makeComponent("list", {
		title,
		resource,
		search: true,
		showDeleteConfirmation: true,
		showCreate: true,
		showEdit: true,
		showDelete: true,
		columns,
		transforms: { ...transforms },
		...(props || {}),
	});

export const makeForm = (title: string, resource: string, fields: any[], isEdit: boolean = false) =>
	makeComponent("form", { isEdit, title, resource, fields });

export const makeInputBase = (input: string, name: string, label: string, props: any) =>
	makeComponent(input, { id: name, label, name, ...props });

export const makeInput = (name: string, label: string, type: any) => makeInputBase("Input", name, label, { type });

export const makeInputSelect = (name: string, label: string, resource: string, rv: string, rl: string, props?: any) =>
	makeInputBase("InputSelect", name, label, { resource, resourceValue: rv, resourceLabel: rl, ...props });

export const makeInputFile = (name: string, label: string, sign: string) =>
	makeInputBase("InputFile", name, label, { signUrl: sign, baseUrl: cloudFront });

export const makeInputReference = (
	name: string,
	label: string,
	resource: string,
	rv: string,
	rl: string,
	rs?: string
) =>
	makeInputBase("InputReference", name, label, {
		resource,
		resourceValue: rv,
		resourceLabel: rl,
		resourceSearch: rs || rl,
	});

export const makeSingleSelect = (name: string, label: string, resource: string, props?: any) =>
	makeInputBase("SingleSelect", name, label, { resource, ...props });

export const makeCheckbox = (name: string, label: string, props?: any) =>
	makeInputBase("InputCheckbox", name, label, { ...props, type: "checkbox" });
