import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { parse } from "json2csv";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import DashboardLayout from "admin/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "admin/examples/Navbars/DashboardNavbar";
import { navbarIconButton } from "admin/examples/Navbars/DashboardNavbar/styles";
import DataTable from "admin/examples/Tables/DataTable";
import MDBox from "admin/components/MDBox";
import MDButton from "admin/components/MDButton";
import MDTypography from "admin/components/MDTypography";
import { MakeComponent } from "blueprint/makeComponent";
import { useList, Options, Row } from "./useList";
import MDSnackbar from "../../../components/MDSnackbar";
import { upload } from "../../../../utils/uploadCsv";

function download(filename: string, text: string) {
	let element = document.createElement("a");
	element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
	element.setAttribute("download", filename);

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

type Props = {
	actions?: ((row: Row) => JSX.Element)[];
	columns: { Header: string; accessor: string; width: string }[];
	description?: string;
	search: boolean;
	showCreate?: boolean;
	showDownloadCsv?: boolean;
	showImportCsv?: boolean;
	showEdit?: boolean;
	showDelete?: boolean;
	title: string;
	bulkCreateChunkSize?: number;
	bulkCreateFields?: string[];
} & Options;

function List(props: Props) {
	const { errorSB, errorMessage, closeErrorSB, bulkCreate, bulkProcess, remove, rows, total, getData, getRawData } = useList(props);
	const [perPage, setPerPage] = React.useState(10);
	const nav = useNavigate();
	const keyButton = useMemo<string>(() => uuid(), [props.resource]);

	const renderErrorSB = (
		<MDSnackbar
			color="error"
			icon="warning"
			title={props.title}
			content={errorMessage}
			dateTime=""
			open={errorSB}
			onClose={closeErrorSB}
			close={closeErrorSB}
			bgWhite
		/>
	);

	const csvExportButton = (
		<MakeComponent
			key={`csvExportButton-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick: async () => {
					const result: any[] = [];
					let next = true;
					let page = 1;
					while (next) {
						const { data } = await getRawData(1000, page);
						result.push(...data);
						page ++;
						next = data.length > 0;
					}
					const csv = parse(result, { fields: props.columns.map((c) => c.accessor) });
					download(`${props.title}.csv`, csv);
				},
			}}
			children={[{ name: "Icon", props: { children: "download" } }]}
		/>
	);

	const csvImportButton = (
		<MakeComponent
			key={`csvImportButton-${keyButton}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "info",
				sx: navbarIconButton,
				onClick() {
					const chunkSize = props.bulkCreateChunkSize || 0;
					const fields = props.bulkCreateFields || [];
					upload(chunkSize, fields).subscribe(async (data) => {
						if (chunkSize) {
							for (let i = 0; i < data.length; i++) {
								await bulkCreate([data[i]]).then(() => getData());
							}
						} else {
							await bulkCreate(data).then(() => getData());
						}
					});
				},
			}}
			children={[{ name: "Icon", props: { children: "upload" } }]}
		/>
	);

	const createButton = (
		<MakeComponent
			key={`createButton-${keyButton}`}
			name={"MDButton"}
			props={{
				color: "info",
				onClick() {
					nav("create");
				},
				children: "create",
			}}
		/>
	);

	const editButton = ({ id }: Row) => (
		<MakeComponent
			key={`editButton-${keyButton}-${id}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "inherit",
				sx: navbarIconButton,
				onClick() {
					nav(`edit/${id}`);
				},
			}}
			children={[{ name: "Icon", props: { children: "edit" } }]}
		/>
	);

	const deleteButton = ({ id }: Row) => (
		<MakeComponent
			key={`deleteButton-${keyButton}-${id}`}
			name={"IconButton"}
			props={{
				size: "small",
				color: "error",
				sx: navbarIconButton,
				onClick() {
					remove(id).then(() => getData());
				},
			}}
			children={[{ name: "Icon", props: { children: "delete" } }]}
		/>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MakeComponent
				name={"MDBox"}
				props={{ py: 3 }}
				children={[
					{
						name: "Card",
						props: {
							sx: {
								overflow: "hidden",
								position: "relative",
							},
						},
						children: [
							{ name: "loading", props: { bulkProcess } },
							{
								name: "MDBox",
								props: {
									px: 3,
									pt: 3,
									pb: 3,
									lineHeight: 1,
								},
								children: [
									{ name: "MDTypography", props: { variant: "h5", fontWeight: "medium", children: props.title } },
									{ name: "MDTypography", props: { variant: "button", color: "text", children: props.description } },
								],
							},
							{
								name: "DataTable",
								props: {
									actions: [
										props.showImportCsv && csvImportButton,
										props.showDownloadCsv && csvExportButton,
										props.showCreate && createButton,
									],
									canSearch: props.search,
									pageCount: Math.ceil(total / perPage),
									onPageChange: (page: number) => getData(perPage, page),
									onSearch: (search: string) => getData(perPage, 1, search),
									setPerPage,
									table: {
										columns: props.columns,
										rows: rows.map((it) => ({
											...it,
											_action: [
												props.showEdit && editButton(it),
												props.showDelete && deleteButton(it),
												...(props.actions || []).map((action) => action(it)),
											],
										})),
									},
								},
							},
						],
					},
				]}
			/>
			{renderErrorSB}
		</DashboardLayout>
	);
}

export default List;
