import React, { forwardRef, ReactNode } from "react";
import MDTypography from "admin/components/MDTypography";

export const Label = forwardRef((props: { label: ReactNode }, ref) => {
	const { label } = props;
	return (
		<MDTypography
			ref={ref}
			component={"div"}
			variant={"caption"}
			sx={{
				pt: "1px",
				px: "5px",
			}}
		>
			{label}
		</MDTypography>
	);
});
