import { TBlueprintRoute } from "../blueprint/types/blueprint";
import { makeList } from "./blueprints-utilities";

export const blueprint: TBlueprintRoute[] = [
	{
		path: "users-import-nic",
		children: [
			{
				path: "",
				component: makeList(
					"User nic import",
					"users-import-nic",
					[
						{ Header: "ID", accessor: "id", width: "10%" },
						{ Header: "User nic", accessor: "number_account" },
					],
					{ showImportCsv: true, bulkCreateChunkSize: 1000, bulkCreateFields: ["CUENTA"] }
				),
			},
		],
	},
];
